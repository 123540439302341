import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Col, Container, Row } from "react-bootstrap";

const UeberMichPage = () => {
  return (
    <Layout>
      <Seo
        title="Über mich"
        // description="Komplementäre Medizin ist ganzheitlich. Sie behandelt den ganzen Menschen – Körper, Geist und Seele – und nicht nur die Symptome."
      />

      <div style={{ position: "relative", zIndex: -1 }}>
        <Container className="g-0 position-absolute" fluid={true}>
          <Row className="g-0 justify-content-end">
            <Col xs={4} className="g-0">
              <StaticImage
                src="../images/blumentopf-cut-header.png"
                alt=""
                placeholder="tracedSVG"
                layout="fullWidth"
                sizes="33.3333333333vw"
                // formats={["auto", "webp", "avif"]}
                // sizes="(max-width: 575px) calc(50vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Container
        className="my-md-5 py-5"
      >
        <Row>
          <Col md={7} className="mb-4">
            <StaticImage
              src="../images/WEM_1910.jpg"
              alt=""
              placeholder="blurred"
              // breakpoints={[
              //   296, 351, 390, 396, 516, 536, 592, 641, 702, 746, 780, 792,
              //   1032, 1072, 1282, 1492,
              // ]}
              // formats={["auto", "webp", "avif"]}
              layout="fullWidth"
              // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 516px, (max-width: 991px) 396px, (max-width: 1199px) 536px, (max-width: 1399px) 641px, 746px"
            />
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <h2 className="section-title">Über mich</h2>
            <p className="lead">
              1977 in Küsnacht ZH geboren, verheiratet, zwei Kinder.
            </p>
            <p>
              Nachdem ich 2010 dem Nachrichten-Journalismus den Rücken zugekehrt
              habe, stürzte ich mich mit viel Leidenschaft in das grosse Feld
              der Naturheilkunde und begleite seither mit Empathie und
              Fachwissen die Menschen, die den Weg in meine Praxis finden.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="py-5">
        <Row>
          <Col md={7}>
            <h2 className="section-title mb-4">Eigene Artikel / Podcast</h2>
            <p className="lead mb-2">
              <a
                href="https://open.spotify.com/episode/3oiTV6Lv11e1R1kzrEF76D?nd=1&si=683ff30b04d747e7"
                target="_blank"
                rel="noreferrer"
              >
                Self-Leadership-Couch &gt;
              </a>
            </p>
            <p className="mb-4">Interview auf der Podcast-Sendung „Self-Leadership Couch“ - Gespräche über (Selbst-) Führung, Potential und Erfüllung.</p>
            <p className="lead mb-2">
              <a
                href="https://www.avogel.ch/de/ihre-ernaehrung/themenuebersicht/basenpulver.php"
                target="_blank"
                rel="noreferrer"
              >
                Übersäuerter Magen und der Griff zum Basenpulver &gt;
              </a>
            </p>
            <p className="mb-4">Artikel im Dr. Vogel Magazin über das vielgelobte Basenpulver.</p>
            <p className="lead mb-2">
              <a
                href="https://docplayer.org/24151039-Wie-johan-boswinkel-die-biophotonen-schwache-von-den-zellen-abgestrahlte-lichtwellen-nutzt-um-die-selbstheilungskraefte-des-koerpers-zu-aktivieren.html"
                target="_blank"
                rel="noreferrer"
              >
                Biophotonen &gt;
              </a>
            </p>
            <p className="mb-4">Artikel im Ode Magazin: Wie Johan Boswinkel die Biophotonen nutzt, um die Selbstheilungskräfte des Körpers zu aktivieren.</p>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="p-3 p-md-5" style={{ position: "relative" }}>
              <div
                className="bg-tertiary m-3 m-lg-5 p-3 p-md-4 p-lg-5"
                style={{ position: "relative", zIndex: 1 }}
              >
                <h3 className="h4">Ausbildung in Heilkunde / Medizin:</h3>
                <ul className="p-3 mb-0">
                  <li>
                    dipl. Heilpraktikerin TEN <br></br>(Paracelsus Schulen
                    Zürich, Krankenkassen anerkannte Ausbildung)
                  </li>
                  <hr />
                  <li>
                    Regulationsdiagnostik A.R.T. I & II & III bei Dr. Dietrich
                    Klinghardt
                  </li>
                  <hr />
                  <li>
                    MFT I <br></br>(Mentalfeld-Technik nach Dr. Dietrich
                    Klinghardt)
                  </li>
                  <hr />
                  <li>
                    PK I <br></br>(Psycho-Kinesiologie nach Dr. Dietrich
                    Klinghardt)
                  </li>
                  <hr />
                  <li>
                    EU Master 's in Psychosocial and Integrated Health Sciences
                    an der Inter-Uni Graz
                  </li>
                  <hr />
                  <li>
                    EU Certificate in Psychosocial and Integrated Health
                    Sciences<br></br> (Inter-Uni Graz)
                  </li>
                  <hr />
                  <li>
                    Diploma in Biontology / Application and Theory of
                    Biophoton-Therapy <br></br> (Institute of Applied Biophoton
                    Sciences Rotterdam)
                  </li>
                  <hr />
                  <li>
                    Hochfrequenztherapie / Schmerztherapie mit dem Gerät VIS
                    INFLUERE
                  </li>
                  <hr />
                  <li>
                    Ausbildung zur Hypnobirthing-Kursleiterin nach Marie F.
                    Mongan
                  </li>
                  <hr />
                  <li> Grundkurs in Barbara Brennan Healing</li>
                  <hr />
                  <li>2 Semester Medizinstudium an der Universität Zürich</li>
                </ul>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 0,
                }}
              >
                <StaticImage
                  src="../images/leistungen-stone-b.png"
                  alt=""
                  placeholder="tracedSVG"
                  objectFit="scale-down"
                  objectPosition="0% 50%"
                  className="w-100 h-100"
                  // breakpoints={[
                  //   228, 258, 273, 296, 318, 348, 351, 366, 456, 516, 546,
                  //   592, 636, 696, 702, 732,
                  // ]}
                  layout="fullWidth"
                  // formats={["auto", "webp", "avif"]}
                  // aspectRatio={3 / 2}
                  // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 258px, (max-width: 991px) 348px, (max-width: 1199px) 228px, (max-width: 1399px) 273px, 318px"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="py-5">
        <Row>
          <Col md={7}>
            <h2 className="section-title">Das Licht in unseren Zellen</h2>
            <p className="lead mb-4">
              Folgende Aussagen des Biophysikers Prof. Fritz-Albert Popp haben
              mein Verständnis von Gesundheit und Medizin schon in jungen Jahren
              geprägt:
            </p>
          </Col>
        </Row>

        <Row className="justify-content-between">
          <Col md={5} xxl={4} className="quote-content pb-4">
            <h3 className="h2 my-3 text-center oblique">
              «Ein Mensch besteht aus etwa 30 Billionen Zellen. Und in jeder
              dieser Zellen laufen pro Sekunde etwa 100’000 chemische Reaktionen
              ab. Als Signalträger kommt hier nur Licht in Frage. Alles andere
              wäre viel zu langsam».
            </h3>
            <p className="text-center fst-italic">Prof. Fritz-Albert Popp</p>
          </Col>

          <Col md={7} className="mt-3">
            <p>
              Diese Lichtteilchen, die nachweislich in unserem Zellkern
              gespeichert sind und für sämtliche Regulationsvorgänge im Körper
              verantwortlich sind, nennt man Biophotonen. Hier wollte ich
              ansetzen, wenn es um Gesundheit geht.
            </p>
            <p>
              <a
                href="https://www.broeckers.com/Popp.htm"
                target="_blank"
                rel="noreferrer"
              >
                Interview mit Prof. Popp über Biophotonen
              </a>
            </p>
            <p>
              Seit 2012 arbeite ich in meiner Praxis mit dem Biophotonen-Gerät
              Chiren. Das Gerät nutzt die Biophotonen - schwache, von den Zellen
              abgestrahlte Lichtwellen, um die Selbstheilungskräfte des Körpers
              zu aktivieren.
            </p>
            <p>
              <a
                href="https://docplayer.org/24151039-Wie-johan-boswinkel-die-biophotonen-schwache-von-den-zellen-abgestrahlte-lichtwellen-nutzt-um-die-selbstheilungskraefte-des-koerpers-zu-aktivieren.html"
                target="_blank"
                rel="noreferrer"
              >
                Interview mit Johan Boswinkel über die Wirkung von Chiren
              </a>
            </p>
            <p>
              Jahre später wurde für mich der Autonomen Response Test A.R.T nach
              Dr. med Dietrich Klinghardt interessant. Auch hier geht es um
              Biophotonen bzw. beruht die Behandlung auf der Interpretation von
              sichtbar gemachten Regulationsvorgängen in dem uns durchdringenden
              und umgebenen Biophotonenfeld. Veränderungen im Biophotonenfeld
              bewirken Veränderungen im autonomen Nervensystem und letztendlich
              im Körper. In der Therapie-Methode nach Dr. Klinghardt wird diese
              Veränderung mit dem kinesiologischen Muskeltest erkannt.
            </p>
            <p>
              Der Name sagt es schon: der PhotonWave ist ein
              Lichttherapie-Gerät, das sowohl die physische als auch
              psychologische Aspekte eines jeden Menschen beeinflusst. Diese Art
              von Lichttherapie, die das elektromagnetische Spektrum des
              sichtbaren Lichts nutzt, hat einen erstaunliche Bandbreite in der
              Anwendung. Traditionell wird Licht- und Farbtherapie gegen
              Depression und Hautkrankheiten eingesetzt. Der PhotonWave kann
              aber noch deutlich mehr.
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid={true} className="g-0">
        <Row className="justify-content-between align-items-end g-0">
          <Col xs={6} md={4} lg={3} xl={2}>
            <StaticImage
              src="../images/line-plant-footer-single.png"
              alt=""
              placeholder="tracedSVG"
              layout="fullWidth"
              // formats={["auto", "webp", "avif"]}
              // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
            />
          </Col>

          <Col xs={6} md={4} lg={3} xl={2}>
            <StaticImage
              src="../images/line-plant-footer-group.png"
              alt=""
              placeholder="tracedSVG"
              layout="fullWidth"
              // formats={["auto", "webp", "avif"]}
              // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default UeberMichPage;
